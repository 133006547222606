<template>
    <!-- 管理员- 习题管理 -->
    <div class="personnel wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline" @submit.native.prevent>
            <div class="formInline-left">
                <el-form-item label="活动类型 :">
                    <el-select v-model="formInline.levelGrade" placeholder="活动类型" style="width: 130px;"
                        @change="filterChange">
                        <el-option label="全部" value="">全部</el-option>
                        <el-option :label="item" :value="index" v-for="(item,index) in levelGrade" :key="item">{{item}}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="题型 :">
                    <el-select v-model="formInline.type" placeholder="题型" style="width: 130px;" @change="filterChange">
                        <el-option label="全部" value="">全部</el-option>
                        <el-option :label="item" :value="index" v-for="(item,index) in questType" :key="index">{{item}}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考察能力 :">
                    <el-select v-model="formInline.abilityId" placeholder="考察能力" style="width: 130px;"
                        @change="filterChange">
                        <el-option label="全部" value="">全部</el-option>
                        <el-option :label="item" :value="index" v-for="(item,index) in ability" :key="item">{{item}}
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="formInline-right flex-align-between">
                <el-input size="mini" style="width: 300px;" v-model="formInline.keys" placeholder="按关键字模糊搜索"
                    suffix-icon="el-icon-search" @input="filterChange">
                </el-input>
            </div>
        </el-form>
        <div class="download-inline">
            <span class="export-btn csp" style="margin-right:15px" @click="openExport">
                <i class="toolbar-icon el-icon-upload2"></i>导入</span>
            <span class="export-btn csp" style="margin-right:15px" @click="deleteChecked">
                <i class="toolbar-icon el-icon-delete"></i>刪除</span>
            <span class="export-btn csp" style="margin-right:15px" @click="openDelete">
                <i class="toolbar-icon el-icon-folder-delete"></i>批量删除</span>
            <span class="export-btn csp" @click="exportQuest">
                <i class="toolbar-icon el-icon-download"></i>导出</span>
        </div>
        <div>
            <ul class="quest-list">
                <li class="quest-item flex-layout" v-for="(item,index) in questList" :key="index">
                    <!-- {{item}} -->
                    <el-checkbox v-model="item.checked" @change="changeChecked(item)"></el-checkbox>
                    <div class="quest-info">
                        <p style="margin-bottom:20px" v-html="handleTitle(item.title)"></p>
                        <div class="flex-between quest-options">
                            <p class="option" v-for="(item,index) in handleAnswerOptions(item.answerOptions)"
                                :key="index">
                                <span>{{item.value}}：</span>
                                <span v-html="handleTitle(item.label)"></span>
                            </p>
                        </div>
                        <div class="flex-layout other-info">
                            <span>正确答案：{{item.rightAnswer || "无"}}</span>
                            <span>题型：{{questType[item.type] || "无"}}</span>
                            <span>图书名称：{{item.bookName || "无"}}</span>
                            <span>考察能力：{{ability[item.abilityId] || "无"}}</span>
                        </div>
                    </div>

                </li>
            </ul>
            <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                :limit.sync="pages.pageSize" @pagination="handlePageChange" :layout="'prev, pager, next, ->, total'">
            </pagination>
        </div>
        <Empty :show="pages.total == 0" :text="'暂无习题'"></Empty>

        <el-dialog title="批量导入习题" :visible.sync="showExportDialog" :close-on-click-modal="clickModalclose"
            :close-on-press-escape="clickModalclose" destroy-on-close>
            <div class="importQuest">
                <p class="tips">
                    <strong>批量导入，系统自动解析习题</strong><br>
                    <span>您需要根据系统特定模板（zip格式）填写后方可上传并自动解析</span>
                </p>
                <el-upload class="upload-demo" action="" :on-remove="handleRemove" :before-remove="beforeRemove"
                    multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList" accept=".zip"
                    :http-request="importQuestZip">
                    <el-button size="mudium" type="primary">点击导入习题</el-button>
                </el-upload>
                <p class="temp-tips-title"><strong>您需要下载模板:</strong></p>
                <p>下载<i style="color:rgb(80, 142, 249)">习题导入模板</i>，按要求填写后可批量导入习题</p>
                <p class="temp">模板：<el-button @click="downloadTemplate">模板1：zip 模板</el-button>
                </p>
            </div>
        </el-dialog>
        <el-dialog title="批量删除习题" :visible.sync="showDeleteDialog" destroy-on-close>
            <div class="importQuest">
                <p class="tips">
                    <strong>批量删除，系统自动解析表格</strong><br>
                    <span> 您需要根据系统特定模板（excel）填写后方可上传并自动解析</span>
                </p>
                <el-upload class="upload-demo" ref="deleteUpload" action="" :before-remove="beforeRemove" multiple
                    :limit="1" :on-exceed="handleExceed" :file-list="fileList" accept=".xlsx,.xls"
                    :http-request="deleteExportQuests" :auto-upload="false" :on-change="deleteQuests">
                    <el-button size="mudium" type="primary">导入删除表</el-button>
                </el-upload>
                <p class="temp-tips-title"><strong>您需要下载模板:</strong></p>
                <p>下载<i style="color:rgb(80, 142, 249)">习题删除模板</i>，按要求填写后可批量删除习题</p>
                <p class="temp">模板：<el-button @click="downloadTemplate">模板1：excel 模板</el-button>
                </p>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import upload from './components/upload-file.vue';
    import {
        cloneDeep
    } from 'lodash/lang'
    import {
        findKey,
        invert
    } from 'lodash/object'
    import {
        mapState
    } from "vuex";

    export default {
        components: {
            upload
        },
        data() {
            return {
                showExportDialog: false,
                showDeleteDialog: false,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 5,
                    total: 0,
                },
                formInline: {
                    keys: '',
                    type: '',
                    abilityId: '',
                    levelGrade: '',
                },
                ability: {},
                levelGrade: {},
                questType: {},
                questList: [],
                fileList: [],
                delIds: [],
                downloadTempMD5: '',
                checkImprotInterval: null,
                loadingInstance: null,
                clickModalclose: true,
                timeCounter: 0,
            };
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            }),
        },
        methods: {
            openExport() {
                this.showExportDialog = true;
                this.getTemplateMD5('competitionImportTemplate');
            },
            openDelete() {
                this.showDeleteDialog = true;
                this.getTemplateMD5('competitionDeleteBatches');
            },
            changeChecked(item) {
                console.log(item);
                if (item.checked) this.delIds.push(item.id);
                if (!item.checked) this.delIds.remove(item.id);
                console.log(this.delIds);
            },
            async getTemplateMD5(mark) {
                let params = {
                    mark: mark
                }
                let resData = await this.$Api.Project.getTemplate(params);
                console.log('试题模板 MD5 ', resData.data);
                this.downloadTempMD5 = resData.data
            },
            async exportQuest() {
                console.log(this.commonbaseURL)
                this.$message.info('正在导出...')
                window.location.href =
                    `${this.commonbaseURL}/quest/qb/qbQuest/export?projectId=${this.currentProject.id}`;
            },
            // 下载模板
            downloadTemplate() {
                this.$message.info('正在下载模板...')
                window.open(this.downloadURL + this.downloadTempMD5);
            },
            // 导入题目压缩包
            async importQuestZip(file) {
                console.log('导入文件', file)
                if (!file) return;
                this.loadingInstance = this.$loading({
                    target: '.el-dialog__body',
                    lock: true,
                    text: '正在导入中，请勿关闭弹窗...',
                    spinner: 'el-icon-loading',
                    // background:'rgba(0, 0, 0, 0.8)'
                });
                this.clickModalclose = false; // 开始上传后 点击点击 modal 将不能关闭 dialog
                let params = {
                    projectId: this.currentProject.id,
                    file: file.file
                }
                let resData = await this.$Api.Project.importQuestZip(params);
                console.log(resData);
                if (resData.code !== 200) {
                    this.fileList = [];
                    this.$message.error(resData.msg)
                } else {
                    this.checkImprotInterval = setInterval(() => {
                        this.timeCounter++
                        this.checkImportState();
                    }, 1000)
                }
            },
            // 查询导入状态
            async checkImportState() {
                let params = {
                    projectId: this.currentProject.id,
                }
                let resData = await this.$Api.Project.getIimportState(params);
                console.log(resData);
                if (resData.data) {
                    this.$message.success('导入成功')
                    this.pages.currentPage = 1;
                    this.getExamQuestList();
                }

                if (!resData.data && this.timeCounter > 60 * 10) {
                    this.$message.error('导入超时，请重试')
                    this.fileList = [];
                }

                if (resData.code !== 200) {
                    this.$message.error(`导入错误 ${resData.msg}`)
                    this.fileList = [];
                }
                this.loadingInstance.close() // 关闭loading状态
                this.clickModalclose = true; // // 上传成功后 点击点击 modal 将可以关闭 dialog
                this.timeCounter = 0; // 计时器 每过1s +1
                clearInterval(this.checkImprotInterval) // 清除定时器
            },

            deleteQuests(option) {
                console.log('options', option)
                if (option.status == 'success') return;
                this.$confirm(`是否确认删除，删除后习题不可用`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        this.$refs.deleteUpload.submit()
                    })
                    .catch(() => {
                        // this.$refs.deleteUpload.clearFiles();
                        this.fileList = [];
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },

            async deleteExportQuests(option) {
                let params = {
                    projectId: this.currentProject.id,
                    file: option.file
                }
                let resData = await this.$Api.Project.deleteExportQuests(params);
                console.log(resData);
                if (resData.code !== 200) {
                    this.fileList = [];
                    this.$message.error(resData.msg)
                } else {
                    this.$message.success('删除成功')
                    this.pages.currentPage = 1;
                    this.getExamQuestList();
                }
            },

            handleRemove(file, fileList) {
                console.log('删除', file, fileList);
            },
            handleExceed(files, fileList) {
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            indexMethod(index) {
                return this.pages.currentPage > 1 ?
                    (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                    index + 1
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.delIds = [];
                this.getExamQuestList();
            },
            filterChange() {
                this.pages.currentPage = 1;
            },
            handleAnswerOptions(options) {
                // console.log(JSON.parse(options));
                return JSON.parse(options);
            },
            handleTitle(content) {
                let result = content.match(/\[(IMG:|AUDIO:|VOICE:|VIDEO:).*\]/);
                console.log("result", result);
                let str = result ? result[0] : null;
                if (str) {
                    str = str.slice(str.indexOf(':') + 1, str.length - 1)
                }
                // console.log(str);
                let string = "";
                // 图片
                if (str && result[1] == "IMG:") {
                    string = content.replace(result[0],
                        `<img src="${this.downloadURL}${str}" class="img-intitle" alt=""/>`);
                    // console.log('IMG', string);
                    return string
                }
                // 音频
                if (str && result[1] == "AUDIO:") {
                    // src="${require("@/assets/guide/audio/10.mp3")}
                    string = content.replace(result[0],
                        `<audio  controls controlsList="nodownload" src="${this.downloadURL}${str}" class="audio-intitle" "></audio>`
                    );
                    // console.log('AUDIO', string);
                    return string
                }
                // 空
                if (result && result[1] == "VOICE:") {
                    string = content.replace(result[0], '');
                    // console.log('VOICE', string);
                    return string
                }
                // 视频
                if (str && result[1] == "VIDEO:") {
                    // src="${require("@/assets/test1.mp4")}"
                    string = content.replace(result[0],
                        `<video class="video-intitle" controls src="${this.downloadURL}${str}" controlsList="nodownload">您的浏览器不支持 video 标签。</video>`
                    );
                    // console.log('VIDEO', string);
                    return string
                }

                return content;
            },
            // 选中删除
            deleteChecked() {
                if (this.delIds.length == 0) {
                    this.$message.error('请选择需要删除的习题');
                    return
                }

                this.$confirm(`是否删除选中的${this.delIds.length}个习题？`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        let checkedStr = this.delIds.join(',')
                        console.log(checkedStr);
                        this.deleteQuestion(checkedStr);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });

            },
            async deleteQuestion(ids) {
                let params = {
                    projectId: this.currentProject.id,
                    ids: ids,
                }
                let resData = await this.$Api.Project.deleteQuestion(params);
                // console.log('选择删除', resData.data);
                if (resData.code == 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    this.delIds = [];
                    this.pages.currentPage = 1;
                    this.getExamQuestList();
                } else {
                    this.$message.error(resData.msg);
                }
            },
            // 获取试题筛选条件
            async getExamQuestFilter() {
                let resData = await this.$Api.Project.getExamQuestFilter();
                // console.log('筛选条件', resData.data);
                this.ability = invert(resData.data.ability);
                this.questType = invert(resData.data.questType)
                this.levelGrade = invert(resData.data.levelGrade)
            },
            filterChange(val) {
                // console.log('筛选', val)
                this.pages.currentPage = 1;
                this.getExamQuestList();

            },
            // 获取试题列表
            async getExamQuestList() {
                let params = {
                    projectId: this.currentProject.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                this.formInline.keys ? params.keys = this.formInline.keys : '';
                this.formInline.type ? params.type = this.formInline.type : '';
                this.formInline.abilityId ? params.abilityId = this.formInline.abilityId : '';
                this.formInline.levelGrade ? params.levelGrade = this.formInline.levelGrade : '';
                let resData = await this.$Api.Project.getExamQuestList(params);
                // console.log('试题列表', resData.data);
                this.questList = resData.data.records;
                this.pages.total = resData.data.total;

                this.questList.map(item => {
                    Vue.set(item, 'checked', false);
                })
            },

        },
        activated() {},
        mounted() {
            this.getExamQuestFilter();
            this.getExamQuestList();

        },
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .personnel {
        padding: 20px 25px;
        box-sizing: border-box;

        .demo-form-inline {
            margin: 15px 0;

            .date-picker {
                width: 320px;
            }

            @media screen and (min-width:1250px) {
                margin: 20px 0;

                .date-picker {
                    width: 400px;
                }
            }
        }

        .download-inline {
            .export-btn {
                color: #595959;

                &:hover {
                    // color: rgba(80, 142, 249, .7);
                    color: rgba(89, 89, 89, .7);
                }
            }

            .export-icon {
                display: inline-block;
                background-image: url("~@/assets/images/admin/export.png");
                background-size: 100% 100%;
                width: 17px;
                height: 16px;
                margin-right: 8px;
                vertical-align: -2px;
            }

            .toolbar-icon {
                font-size: 16px;
                margin-right: 5px;
            }
        }

        .quest-list {
            margin-top: 25px;
        }

        .quest-item {
            padding-bottom: 15px;
            margin-bottom: 22px;
            border-bottom: 1px solid #F2F2F2;


            .quest-info {
                margin: 0 10px 0 20px;
                width: 100%;
            }

            .quest-options {
                flex-flow: row wrap;

                .option {
                    width: calc(50% - 25px);
                    margin-top: 10px;
                    display: flex;
                    padding-right: 25px;
                }
            }

            .other-info {
                flex-flow: row wrap;
                margin-top: 25px;

                &>* {
                    width: 50%;
                    margin-top: 10px;
                }
            }


        }

        /deep/ .img-intitle {
            width: 100px;
            height: 100px;
            margin: 0 10px;
            background-color: peachpuff;
            vertical-align: middle;
        }

        /deep/ .audio-intitle {
            height: 35px;
            margin: 0 10px;
            vertical-align: middle;
        }



        /deep/ .video-intitle {
            max-width: 300px;
            max-height: 200px;
            margin: 0 10px;
            vertical-align: middle;
        }

        .importQuest {
            padding: 0 20px;

            .tips {
                font-size: 17px;

                &>span {
                    font-size: 15px;
                }
            }

            .upload-demo {
                margin: 20px 15px 35px 15px;
            }


            .temp-tips-title {
                font-size: 15px;
                margin-bottom: 10px;
            }

            .temp {
                margin-top: 20px;

                /deep/ .el-button {
                    padding: 8px 20px;
                }
            }
        }



        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__title {
                font-size: 15px;
                color: #333;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .btns-box {
                text-align: center;
            }
        }

    }
</style>